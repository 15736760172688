import React, { useState, useEffect, useRef } from 'react';
import './App.css';

function App() {
  const [currentChat, setCurrentChat] = useState({ messages: [] });
  const [input, setInput] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    // Scroll to the bottom of the chat messages whenever the messages change
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [currentChat.messages]);

  const handleSend = () => {
    if (input.trim()) {
      const userMessage = { type: 'user', text: input };
      const updatedCurrentChat = { ...currentChat, messages: [...currentChat.messages, userMessage] };
  
      // Update the current chat
      setCurrentChat(updatedCurrentChat);
  
      // Call the chat API to get the bot response
      fetch('https://api.sureevents.in/echo', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ question: input }),
      })
        .then(response => response.json())
        .then(data => {
          const botMessage = { type: 'bot', text: data.answer };
          const updatedChat = { ...updatedCurrentChat, messages: [...updatedCurrentChat.messages, botMessage] };
  
          // Update the current chat
          setCurrentChat(updatedChat);
        });
  
      setInput('');
      setSuggestions([]);
    }
  };
  
  const handleInputChange = async (e) => {
    const value = e.target.value;
    setInput(value);
  
    if (value.trim()) {
      try {
        // Make API call to fetch suggestions
        const response = await fetch(`https://api.sureevents.in/suggestions?query=${value}`);
        if (response.ok) {
          const data = await response.json();
          console.log(data.suggestions["suggestions"])
          setSuggestions(data.suggestions["suggestions"]);
        } else {
          console.error('Failed to fetch suggestions');
          setSuggestions([]);
        }
      } catch (error) {
        console.error('Error fetching suggestions:', error);
        setSuggestions([]);
      }
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    const words = input.trim().split(' ');
    words[words.length - 1] = suggestion;
    setInput(words.join(' '));
    setSuggestions([]);
  };

  return (
    <div className="App">
      <div className="chat-container">
        <div className="chat-header">
          <h2>Chat Interface</h2>
        </div>
        <div className="chat-messages">
          {currentChat.messages.map((message, index) => (
            <div key={index} className={`chat-message ${message.type}`}>
              <span>{message.text}</span>
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>
        <div className="chat-input-container">
          <input
            type="text"
            value={input}
            onChange={handleInputChange}
            placeholder="Type a message..."
            className="chat-input"
            onKeyDown={(e) => e.key === 'Enter' && handleSend()}
          />
          <button onClick={handleSend} className="send-btn">Send</button>
          {suggestions.length > 0 && (
            <div className="suggestions">
              {suggestions.map((suggestion, index) => (
                <div
                  key={index}
                  className="suggestion"
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  {suggestion}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;